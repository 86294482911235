import { $http } from "@/api";
import { authHeader } from "@/authHeader";

function fetchCashbackList(page = 1) {
  return $http.get(`/admin/cashback/entity`, {
    params: { page, limit: 25 },
    headers: authHeader(),
  });
}

function fetchCashback(page = 1, filters, cashbackStatus) {
  return $http.get(`/admin/cashback`, {
    params: {
      page,
      limit: 25,
      filter: {
        ...filters,
        status: cashbackStatus,
      },
    },
    headers: authHeader(),
  });
}

function fetchCashBackInfo(params) {
  const { cashbackType, cashbackId } = params;
  return $http.get(`/admin/cashback/types/${cashbackType}/${cashbackId}`, {
    headers: authHeader(),
  });
}

function searchProductByName(productName) {
  return $http.get(`/admin/product/matching/${productName}`, {
    headers: authHeader(),
  });
}

function availUnits(productId) {
  return $http.get(`/admin/productUnit/product/${productId}`, {
    headers: authHeader(),
  });
}

function availProviders(params) {
  const { productId, unitId } = params;
  return $http.get(`/admin/product/${productId}/units/${unitId}/providers`, {
    headers: authHeader(),
  });
}

function editCashback(params) {
  const { cashbackId, cashbackType, formData } = params;
  return $http.patch(
    `/admin/cashback/types/${cashbackType}/${cashbackId}`,
    {
      ...formData,
    },
    {
      headers: authHeader(),
    }
  );
}

function deleteCashback(params) {
  const { id, type, status } = params;
  const operation = status === "Deactivated" ? "deactivation" : "delete";
  if (operation === "deactivation") {
    return $http.put(`/admin/cashback/types/${type}/${id}/deactivation`, null, {
      headers: authHeader(),
    });
  }
  return $http.delete(`/admin/cashback/types/${type}/${id}`, {
    headers: authHeader(),
  });
}

function uploadCashbackSheetAction(formData, startDate, endDate) {
  return $http
    .post(
      `/admin/cashback/redeem/import?startDate=${startDate}&endDate=${endDate}`,
      formData,
      {
        headers: authHeader(),

        responseType: "arraybuffer",
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

function fetchExportCashback(filters) {
  let requestParams = {
    filters,
  };
  return $http.get(`/admin/cashback/export`, {
    headers: authHeader(),
    responseType: "arraybuffer",
    params: requestParams,
  });
}

function exportSegmentedUsersListTemplate(action, filters) {
  const url = action
    ? `/admin/cashback/export/segmentation/template`
    : `/admin/cashback/export/segmentation`;

  return $http.get(url, {
    headers: authHeader(),
    responseType: "arraybuffer",
    params: {
      filters,
      action,
    },
  });
}

function uploadCashbackSegmentationSheetAction(type, requestAction, formData) {
  return $http
    .post(
      `/admin/cashback/import/segmentation?action=${requestAction}&type=${type}`,
      formData,
      {
        headers: authHeader(),
        responseType: "arraybuffer",
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function createCashback(requestBody) {
  return $http.post(`/admin/cashback`, requestBody, {
    headers: authHeader(),
  });
}

function createProductCashback(requestBody) {
  return $http.post(`/admin/cashback/products`, requestBody, {
    headers: authHeader(),
  });
}

export const cashbackService = {
  createProductCashback,
  availProviders,
  availUnits,
  searchProductByName,
  editCashback,
  fetchCashBackInfo,
  deleteCashback,
  createCashback,
  fetchCashback,
  fetchCashbackList,
  uploadCashbackSheetAction,
  fetchExportCashback,
  exportSegmentedUsersListTemplate,
  uploadCashbackSegmentationSheetAction,
};
